
import ApiService from "@/core/services/ApiService"

import { useRoute, useRouter } from "vue-router"
import { computed, defineComponent, onMounted, ref, watch } from "vue"
import { Edit } from "@element-plus/icons-vue"
import BranchComponent from "@/components/general/definitions/branch/branch.vue"
import { Branch } from "@/core/models/Branch"
import { SelectModel } from "@/core/models/SelectModel"
import swalConfirm from "@/core/helpers/swal-confirm"

export default defineComponent({
  name: "admin-branch-list",
  components: { BranchComponent },
  props: ["add"],
  setup(props) {
    const route = useRoute()
    const router = useRouter()
    const branchList = ref<Branch[]>([])
    const filterOnlyActives = ref(true)
    const isLoading = ref(false)
    const newBranch = ref(false)
    const activeBranchId = ref<string | undefined>()
    const activeBusinessId = ref<string>()
    const accountList = ref<SelectModel[]>([])
    const businessList = ref<SelectModel[]>([])
    const isBusinessSelectLoading = ref(false)
    const activeAccountId = ref<string | undefined>()
    watch(
      () => route.params.branch_id,
      newValue => {
        activeBranchId.value = newValue as string
      }
    )

    watch(
      () => props.add,
      newValue => {
        newBranch.value = true == newValue
      }
    )

    const getAccountList = async () => {
      const { data } = await ApiService.get("account")
      accountList.value = data.map(x => ({ name: x.title, id: x.id }))
    }

    const branchListFiltered = computed(() => {
      return branchList.value.filter(x => {
        return x.isActive || !filterOnlyActives.value
      })
    })

    async function getBranchList() {
      const config = {
        params: {
          businessId: activeBusinessId.value ? activeBusinessId.value : null,
          accountId: activeAccountId.value,
        },
      }
      isLoading.value = true
      const { data } = await ApiService.query("branch", config)
      branchList.value = data.sort((a, b) => a.title.localeCompare(b.title))
      isLoading.value = false
    }

    const getBusinessList = async accountId => {
      isBusinessSelectLoading.value = true
      const { data } = await ApiService.get("account/" + accountId + "/business")
      businessList.value = data.filter(x => x.isActive).map(x => ({ name: x.title, id: x.id }))
      if (businessList.value.length == 1) {
        activeBusinessId.value = businessList.value[0].id
        await getBranchList()
      }
      isBusinessSelectLoading.value = false
    }

    const handleBranchDrawerClose = () => {
      router.push({ name: "branch-list" })
    }

    const removeBranch = async branchId => {
      const confirmResult = await swalConfirm()
      if (confirmResult.isConfirmed) {
        await ApiService.get("branch/remove/" + branchId)
        await getBranchList()
      }
    }

    onMounted(async () => {
      activeBranchId.value = route.params.branch_id as string
      newBranch.value = props.add
      await getAccountList()
    })

    const onAccountChange = async () => {
      await getBusinessList(activeAccountId.value)
    }

    return {
      onAccountChange,
      branchList,
      Edit,
      filterOnlyActives,
      branchListFiltered,
      isLoading,
      getBranchList,
      newBranch,
      activeBranchId,
      removeBranch,
      handleBranchDrawerClose,
      businessList,
      activeBusinessId,
      isBusinessSelectLoading,
      accountList,
      activeAccountId,
    }
  },
})
